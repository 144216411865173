import React, { useState } from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";

export default function MobileFooter() {
  const [active, setActive] = useState(1);

  const openMenu = () =>
    document.getElementById("menu-bar").classList.add("active");

  return (
    <div className="mobile-footer">
      <ul className="row" id="scrollDiv">
        <Link to="/" onClick={() => setActive(1)} className="col">
          <li className={`${active === 1 ? "active" : ""}`}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z"
                fill="#171717"
              />
            </svg>

            <span>Bosh sahifa</span>
          </li>
        </Link>
        <Link
          className="col"
          to="/manzil"
          onClick={() => {
            setActive(2);
          }}
        >
          <li className={`${active === 2 ? "active" : ""}`}>
            <svg
              width="22px"
              height="22px"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              class="iconify iconify--gis"
              preserveAspectRatio="xMidYMid meet"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M50.001 0C33.65 0 20.25 13.36 20.25 29.666c0 6.318 2.018 12.19 5.433 17.016L46.37 82.445c2.897 3.785 4.823 3.066 7.232-.2l22.818-38.83c.46-.834.822-1.722 1.137-2.629a29.28 29.28 0 0 0 2.192-11.12C79.75 13.36 66.354 0 50.001 0zm0 13.9c8.806 0 15.808 6.986 15.808 15.766c0 8.78-7.002 15.763-15.808 15.763c-8.805 0-15.81-6.982-15.81-15.763c0-8.78 7.005-15.765 15.81-15.765z"
                  fill="#000000"
                ></path>
                <path
                  d="M68.913 48.908l-.048.126c.015-.038.027-.077.042-.115l.006-.011z"
                  fill="#000000"
                ></path>
                <path
                  d="M34.006 69.057C19.88 71.053 10 75.828 10 82.857C10 92.325 26.508 100 50 100s40-7.675 40-17.143c0-7.029-9.879-11.804-24.004-13.8l-1.957 3.332C74.685 73.866 82 76.97 82 80.572c0 5.05-14.327 9.143-32 9.143c-17.673 0-32-4.093-32-9.143c-.001-3.59 7.266-6.691 17.945-8.174c-.645-1.114-1.294-2.226-1.94-3.341z"
                  fill="#000000"
                ></path>
              </g>
            </svg>
            <span>Kutubxona Manzili</span>
          </li>
        </Link>
        <Link
          to="/hissa"
          className=" col"
          onClick={() => {
            setActive(3);
          }}
        >
          <li className={`${active === 3 ? "active" : ""}`}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
                fill="#171717"
              />
            </svg>
            <span>Hissa qo'shish</span>
          </li>
        </Link>
        <Link
          to="/"
          className="col bg-transparent border-0 w-auto py-0"
          onClick={(e) => {
            e.preventDefault();
            openMenu();
          }}
        >
          <li className={`${active === 4 ? "active" : ""}`}>
            <div className="p-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 64 64"
              >
                <g>
                  <path d="M31,3C31,1.895 30.105,1 29,1L3,1C1.895,1 1,1.895 1,3L1,29C1,30.105 1.895,31 3,31L29,31C30.105,31 31,30.105 31,29L31,3Z" />
                  <path d="M31,35C31,33.895 30.105,33 29,33L3,33C1.895,33 1,33.895 1,35L1,61C1,62.105 1.895,63 3,63L29,63C30.105,63 31,62.105 31,61L31,35Z" />
                  <path d="M63,3C63,1.895 62.105,1 61,1L35,1C33.895,1 33,1.895 33,3L33,29C33,30.105 33.895,31 35,31L61,31C62.105,31 63,30.105 63,29L63,3Z" />
                  <g transform="matrix(1.08333,0,0,1,30.6667,31)">
                    <path d="M29.846,10.297C29.846,5.715 26.417,2 22.188,2L9.812,2C5.583,2 2.154,5.715 2.154,10.297L2.154,23.703C2.154,28.285 5.583,32 9.812,32L22.188,32C26.417,32 29.846,28.285 29.846,23.703L29.846,10.297ZM26.154,10.297L26.154,23.703C26.154,26.076 24.378,28 22.188,28L9.812,28C7.622,28 5.846,26.076 5.846,23.703L5.846,10.297C5.846,7.924 7.622,6 9.812,6L22.188,6C24.378,6 26.154,7.924 26.154,10.297Z" />
                  </g>
                </g>
              </svg>
            </div>
            <span>Menu</span>
          </li>
        </Link>
      </ul>
      <Menu />
    </div>
  );
}
