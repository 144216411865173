import React from "react";

function Contract() {
	return (
		<div id="wrap">
			<iframe
				id="frame"
				title="test"
				src="https://docs.google.com/document/d/e/2PACX-1vQTgfba_UBMknOia56OFTO8hcIM_2uNZU5JQNCSUu4InOWNs8eCQPjK5W68m7d34BCXLEdhiZEbhvhi/pub?embedded=true"
			></iframe>
		</div>
	);
}

export default Contract;
