import React from "react";
import { setHeader } from "../../utils/head_tools";
import TelegramEmbed from "react-telegram-embed";

export default function ToContribute() {
	setHeader({ title: "Hissa qo'shish" });
	return (
		<div className="container my-3">
			<TelegramEmbed src="https://t.me/kutubxona_hissadorlari/3" />
		</div>
	);
}
