import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { getReadingBooks } from "../../../../api/getReadingBooks";
import { NO_IMAGE } from "../../../../constants/constants";

const customStyles = {
  content: {
    maxWidth: "65%",
    maxHeight: "max-content",
  },
  overlay: {
    backgroundColor: "rgba(77,77,77,0.6)",
    overflow: "auto",
    zIndex: "3",
  },
};

export default function ProductModal(props) {
  const { product, modal, setModal } = props;
  const notBusyBooksCount = product?.stocks?.filter(
    (busy) => busy.busy === false
  );
  const someBusy = product?.stocks?.some((busy) => busy.busy === true);
  const [busyBooks, setBusyBooks] = useState();
  useEffect(() => {
    if (someBusy) {
      getReadingBooks(product.id).then((res) => {
        setBusyBooks(res?.data);
      });
    }
  }, [product, someBusy]);

  function groupBy(list, keyGetter) {
    const map = {};
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map[key];
      if (!collection) {
        map[key] = [item];
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  let groupedByDate;
  if (busyBooks) {
    groupedByDate = Object.entries(
      groupBy(busyBooks, (item) => item.returningDate.slice(0, 10))
    );
  }

  return (
    <Modal
      isOpen={modal}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={() => setModal(false)}
      className="modal-dialog"
      id="product-modal"
    >
      <div className="modal-content">
        <div className="header">
          <button
            className="btn back-button p-0"
            onClick={() => setModal(!modal)}
          >
            <img src="../../assets/images/arrow_back.svg" alt="back" />
          </button>
          <h3>Kitob</h3>
        </div>
        <div className="book">
          <div className="product-media">
            <img src={product.image || NO_IMAGE} alt={product.name} />
          </div>
          <div className="product-info">
            <div className="product-name">
              <h2>
                <b>{product.name}</b>
              </h2>
            </div>
            {product.author?.name ? (
              <div className="product-author">
                <h3>
                  <b>Muallif:</b>&nbsp;{product.author?.name}
                </h3>
              </div>
            ) : null}

            {product.description ? (
              <div className="about-product">
                <h3>
                  <b>Kitob haqida:</b> &nbsp;{product.description}
                </h3>
              </div>
            ) : null}
            <div className="product-count">
              <h3>
                <b>Umumiy kitoblar soni:</b>&nbsp;
                {product.stocks?.length}
              </h3>
              <h3>
                <b>Bo'sh kitoblar:</b>&nbsp;
                {notBusyBooksCount.length}
              </h3>
              <h3>
                <b>Kitob raqami:</b>&nbsp;
                {product.id}
              </h3>
            </div>
            {groupedByDate ? (
              <div className="reading-books">
                <br />
                <h3>
                  <b>Bo'shash muddatlari:</b>
                </h3>
                <div className="dates">
                  {groupedByDate.map((item, i) => (
                    <div className="data" key={i}>
                      <h3>
                        <b>{item[1].length} ta</b> &nbsp;_ _ _ _ _ _ _ _ _ _ _ _
                        _&nbsp;
                        <b>
                          {item[0]
                            .slice(0, 10)
                            .replace("-", ".")
                            .replace("-", ".")
                            .split(".")
                            .reverse()
                            .join(".")}
                        </b>
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
}
