import React from "react";
import { Route, Switch } from "react-router-dom";
import ProductPage from "./ProductPage";
import NotFound from "../pages/NotFound";

export default function ProductsRoute() {
	return (
		<Switch>
			<Route path="/product/:id" component={ProductPage} />
			<Route component={NotFound} />
		</Switch>
	);
}
