import React, { useState } from "react";
import "react-tabs/style/react-tabs.css";
import SearchForm from "../../../../components/common/partials/SearchForm/SearchForm";
import SearchResult from "../../partials/SearchForm/SearchResult";
import Books from "../../../../views/home/components/blocks/Books";

export default function CategoriesTab() {
  const [books, setBooks] = useState();
  const [loader, setLoader] = useState(true);
  const [filter, setFilter] = useState({ isBusy: "", query: "" });
  const [inputIsClicked, setInputIsClicked] = useState(false);
  const [active, setActive] = useState(1);

  return (
    <>
      <div className="horizontal-tab-top">
        <div className="container" id="books">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="title">Kitoblar</h2>
            </div>
          </div>
        </div>

        <div className="books">
          <div
            className={`sticky-wrapper sticky-header ${
              inputIsClicked ? `show` : ``
            }`}
          >
            <div className="container ">
              <div className="row">
                <div className="col-12">
                  <ul className="display-flex w-100 scrolling-tabs">
                    <li
                      onClick={() => {
                        setActive(1);
                        setFilter({ isBusy: "", query: "" });
                      }}
                      className={`${active === 1 ? `active` : ``}`}
                    >
                      Barchasi
                    </li>
                    <li
                      onClick={() => {
                        setActive(2);
                        setFilter({ isBusy: true });
                      }}
                      className={`${active === 2 ? `active` : ``}`}
                    >
                      Band
                    </li>
                    <li
                      onClick={() => {
                        setActive(3);
                        setFilter({ isBusy: false });
                      }}
                      className={`${active === 3 ? `active` : ``}`}
                    >
                      Bo'sh
                    </li>
                    <div className="search-component">
                      <SearchForm
                        setLoader={setLoader}
                        setBooks={setBooks}
                        inputIsClicked={inputIsClicked}
                        setInputIsClicked={setInputIsClicked}
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {books ? (
                  <SearchResult books={books} loader={loader} />
                ) : (
                  <Books filter={filter} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
