import React, { useEffect, useState } from "react";
import Pagination from "../../../../components/features/details/Pagination";
import { getBooks } from "../../../../api/allBooks";
import ProductCard from "../product_card/ProductCard";
import Loader from "../../../../components/features/loaders/Loader";
function toTop() {
  window.scrollTo({
    top:
      window.scrollY +
      document.getElementById("books").getBoundingClientRect().top -
      30,
    left: 0,
    behavior: "smooth",
  });
}

export default function Books({ filter }) {
  const [books, setBooks] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [totalCount, setTotalCount] = useState(20);
  useEffect(() => {
    setLoader(true);
    getBooks(20, page, filter?.isBusy, filter?.query).then((res) => {
      setBooks(res?.data?.items);
      setTotalCount(res?.data?.totalCount);
      setLoader(false);
    });
  }, [page, filter]);

  return (
    <>
      <div className="mini-products">
        <div className="row">
          {loader ? (
            <Loader />
          ) : (
            <>
              {books?.length > 0
                ? books.map((item, index) => (
                    <div className="col-md-3 col-12" key={index}>
                      <ProductCard product={item} />
                    </div>
                  ))
                : null}
            </>
          )}
        </div>
      </div>
      <div className="page-pagination">
        <Pagination
          pageCount={Math.ceil(totalCount / 20)}
          onChange={({ selected }) => {
            toTop();
            setPage(1 + selected);
          }}
        />
      </div>
    </>
  );
}
