import axios from "axios";
import { URL_LIBRARY_API } from "../constants/constants";
export const getProduct = (id) => {
  return axios.get(`${URL_LIBRARY_API}/app/books/${id}`);
};
export const getProductStatuses = (id, locationId) => {
  return axios.get(
    `${URL_LIBRARY_API}/app/books/${id}/statuses?locationId=${locationId}`
  );
};
