import React, { useEffect, useState } from "react";
import { getStats } from "../../../../api/getStats";
import Loader from "../../../../components/features/loaders/Loader";

export default function Important() {
  const [allStats, setAllStats] = useState();

  useEffect(() => {
    getStats().then((res) => {
      setAllStats(res.data);
    });
  }, []);
  return (
    <div className="all-statistics">
      <div className="container">
        <div className="row mt-md-5 mt-3 mb-5">
          <div className="col-12 fs-5">
            {!allStats ? (
              <Loader />
            ) : (
              <>
                <div
                  style={{
                    marginBottom:"10px",
                    fontSize: 26,
                  }}
                >
                  <b>📚 Zarur(yetishmayotgan) kitoblar</b>
                </div>
                {allStats.few_books?.map((book, index) => (
                  <div key={index}>
                    <b>{index + 1}.</b> {book.name}{" "}
                    <span style={{ color: "black", marginLeft: "10px" }}>
                      <i>
                        (Umumiy: {book.total}ta | Band: {book.busies}ta)
                      </i>
                    </span>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
