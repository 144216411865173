import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserExpiredInfo } from "../../api";
import Loader from "../../components/features/loaders/Loader";
import { getLeasedDays } from "../../utils";
import { setHeader } from "../../utils/head_tools";

export default function RentExpiredPage() {
  setHeader({ title: "Ijara" });
  const { phone } = useParams();
  const [localStore, setLocalStore] = useState({ loading: false });

  const getUserInfo = async () => {
    setLocalStore({ loading: true });
    try {
      const { data } = await getUserExpiredInfo(phone);
      setLocalStore({
        loading: false,
        data: data,
      });
    } catch (error) {
      setLocalStore({ loading: false });
      console.log(error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  if (localStore.loading) {
    return <Loader />;
  }

  if (!localStore.data) {
    return (
      <div
        className="container my-5"
        style={{
          fontWeight: "bolder",
          textAlign: "center",
          fontSize: 30,
        }}
      >
        Sahifa topilmadi!
      </div>
    );
  }

  return (
    <div className="container py-4 expired-page">
      <div className="m-2">
        <b>Assalamu alaykum</b>
      </div>
      {localStore.data.length > 1 && (
        <div className="ep__box">
          <b>
            {localStore.data[0].user.firstName}{" "}
            {localStore.data[0].user.lastName}{" "}
          </b>
          sizda jami <b className="text-danger">{localStore.data.length} ta </b>
          vaqtida qaytarilmagan kitob mavjud!
        </div>
      )}
      {localStore.data.map((item, i) => (
        <div
          key={i}
          className="ep__box"
        >
          Hurmatli{" "}
          <b>
            {item.user.firstName} {item.user.lastName}
          </b>{" "}
          siz <b>{new Date(item.leasedAt).toLocaleDateString("ru")}</b> sanasida{" "}
          <b>kutubxonadan “{item.stock.book.name}”</b> kitobini o’qish uchun
          olib ketgansiz. Kitobni{" "}
          <b>{new Date(item.returningDate).toLocaleDateString("ru")}</b>{" "}
          sanasidan <b>qaytarishga va’da bergansiz.</b>
          <br />
          <br />
          <b> Ijaraga olgan kitobingizni </b>
          <b className="text-danger">
            {getLeasedDays({ leasedAt: item.returningDate })} kun kechiktiribsiz
          </b>{" "}
          <br />
          <br /> Iltimos kitobni qaytaring.{" "}
          <b className="text-danger">
            Bu ishingiz kutubxonamiz uchun katta zarar!
          </b>
          <br />
          <br />{" "}
          <b className="text-success">
            Bepul kutubxonamiz uzoq vaqt ishlashi uchun kitoblarni vaqtida
            qaytarishingiz so’raymiz!
          </b>
          <br />
          <br />
          <Link to="/manzil">
            <b> 👉 Manzil</b>
          </Link>
        </div>
      ))}
      <div className="ep__box">
        <b>⚠️ Diqqat</b> <br />
        <br />
        Agar sizning
        <b>
          {" "}
          {localStore.data[0].user.firstName} {localStore.data[0].user.lastName}{" "}
        </b>
        ismli shaxsga hech qanday aloqangiz bo'lmasa yoki kitoblarni{" "}
        <b>topshirgan</b> bo'lsangiz quyidagi telegram akkauntga murojaat
        qoldiring!
        <br />
        <br />
        <a href="https://t.me/kutubxonachi_1">
          👉 <b>@kutubxonachi_1</b>
        </a>
      </div>
    </div>
  );
}
