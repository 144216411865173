import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Logo";

export default function Menu() {
  const { pathname } = useLocation();
  const closeMenu = () =>
    document.getElementById("menu-bar").classList.remove("active");

  useEffect(() => {
    return () => closeMenu();
  }, [pathname]);

  return (
    <div id="menu-bar" className="menu-bar">
      <div className="menu-top d-flex justify-content-between">
        <Logo />
        <button className="btn bg-trasparent" onClick={closeMenu}>
          <img width={20} src="/assets/images/close.svg" alt="close" />
        </button>
      </div>
      <div className="menu-list">
        <Link to="/">Asosiy sahifa</Link>
        <Link to="/manzil">Kutubxona manzili</Link>
        <Link to="/hissa">Hissa qo'shish</Link>
        <Link to="/zarur">Zarur kitoblar</Link>
        <Link to="/statistika">Kutubxona statistikasi</Link>
      </div>
    </div>
  );
}
