import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";

export default function Header() {
	// const token = localStorage.getItem("token");
	// const user = localStorage.getItem("user");

	// const loginStyle = token
	//   ? { padding: 0, background: "transparent" }
	//   : { padding: "6px 12px", background: "#3773fa" };

	return (
		<>
			<header className="header">
				<div className="header-top">
					<div className="container">
						<div className="row">
							<div className="col-12 display-flex justify-content-between">
								<div className="header-left w-100 display-flex justify-content-between">
									<Logo />
									<ul className="display-flex">
										<li>
											<Link to="/manzil">Manzil</Link>
										</li>
										<li>
											<Link to="/hissa">Hissa qo'shish</Link>
										</li>
										<li>
											<Link to="/zarur">Zarur kitoblar</Link>
										</li>
										<li>
											<Link to="/statistika">Statistika</Link>
										</li>
									</ul>
								</div>
								{/* <div className="header-right">
                  <button
                    to={token ? `/profile` : `/login/check-user-phone`}
                    className="btn login-button"
                    disabled={true}
                    style={loginStyle}
                  >
                    {token ? (
                      <img
                        src={
                          // user.image
                          // ? user.image :
                          "/assets/images/profile/user.png"
                        }
                        alt={user.firstName}
                      />
                    ) : null}
                    {token ? "" : "Kirish"}
                  </button>
                </div> */}
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}
