import React, { Fragment, useEffect, useState } from "react";
import { getStats } from "../../../../api/getStats";
import Loader from "../../../../components/features/loaders/Loader";
import { setHeader } from "../../../../utils/head_tools";
const GetCharts = React.lazy(() => import("../GetCharts"));

export default function Satistics() {
	const [allStats, setAllStats] = useState();

	useEffect(() => {
		setHeader({ title: "Statistika" });
		getStats().then((res) => {
			setAllStats(res.data);
		});
	}, []);

	return (
		<div className="all-statistics">
			<div className="container">
				<div className="row mt-4 mb-5">
					<div className="col-12 fs-5">
						<div>
							<b
								style={{
									fontSize: 26,
								}}
							>
								📊 Kutubxona statistikasi
							</b>
						</div>
						<br />
						{!allStats ? (
							<Loader />
						) : (
							<Fragment>
								<div>
									<b>20.04.2021 sanadan boshlab hozirgi kungacha</b>
								</div>
								<br />
								<div>
									<b>📚 Barcha kitoblar:</b> {allStats.books_count} ta
								</div>
								<div>
									<b>🧑‍🚀 Kitobxonlar: </b>
									{allStats.librarians_count} ta
								</div>
								<div>
									<b>
										📖 Umumiy o'qish uchun berilgan kitoblar (ijaralar) soni:{" "}
									</b>
									{allStats.rents_count} ta
								</div>
								<div>
									<b>👨‍🏫 Erkaklar: </b>
									{allStats.gender?.male} | <b>🧑‍🏫 Ayollar: </b>
									{allStats.gender?.female}
								</div>
								<div>
									<b>📖 Ayni vaqtda o'qilayotgan kitoblar: </b>
									{allStats.reading_books_count} ta
								</div>
								<div>
									<b>
										⚠️ Ayni vaqtda kelishilgan muddatda qaytarilmagan kitoblar:{" "}
									</b>
									{allStats.expired_leases} ta
								</div>
								<div>
									<b>📖 Kunlik beriladigan kitoblar soni (o'rtacha): </b>
									{allStats.dayly_leasing_books_avarage_count_of_last_month} ta
								</div>
								<div>
									<b>📖 Oxirgi oyda berilgan kitoblar: </b>
									{allStats.leased_books_count_of_last_month} ta
								</div>
								<div>
									<b>📖 Oxirgi haftada berilgan kitoblar: </b>
									{allStats.leased_books_count_of_last_week} ta
								</div>
								<div>
									<b>📖 Oxirgi 24 soat ichida berilgan kitoblar: </b>
									{allStats.leased_books_count_of_last_24_hours} ta
								</div>
								<br />

								<div>
									<React.Suspense fallback={<Loader />}>
										<GetCharts stats={allStats} />
									</React.Suspense>
								</div>
								<br />
								<div
									style={{
										fontSize: 26,
									}}
								>
									<b>📖 O'tgan haftada eng ko'p o'qilgan kitoblar</b>
								</div>
								{allStats.top_books_last_week?.map((book, index) => (
									<div key={index}>
										<b>{index + 1}.</b> {book.name} - {book.count} marta
									</div>
								))}
								<br />
								<div
									style={{
										fontSize: 26,
									}}
								>
									<b>📚 Top 100 kitoblar</b>
								</div>
								<div>
									<i>Eng ko'p o'qilganlik bo'yicha</i>
								</div>
								{allStats.top_books?.map((book, index) => (
									<div key={index}>
										<b>{index + 1}.</b> {book.name} - {book.count} marta
									</div>
								))}
								<br />
								<div
									style={{
										fontSize: 26,
									}}
								>
									<b>🧑‍🚀 Top {allStats.top_librarians.length} kitobxon</b>
								</div>
								<div>
									<i>Eng ko'p kitob o'qiganlik bo'yicha</i>
								</div>
								{allStats.top_librarians?.map((librarians, index) => (
									<div key={index}>
										<b>{index + 1}.</b> {librarians.lastName}
										{librarians.firstName} - {librarians.count} marta
									</div>
								))}
								<br />
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
