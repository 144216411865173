import React from "react";
import ProductCard from "../home/components/product_card/ProductCard";
export default function SearchPage({ books }) {
  return (
    <div className="books">
      <div className="mini-products">
        <div className="row">
          {books?.length > 0
            ? books.map((item, index) => (
                <div className="col-md-3 col-12" key={index}>
                  <ProductCard product={item} />
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}
