import React, { useCallback } from "react";
import { searchBooks } from "../../../../api/search";
import { debounce } from "../../../../utils/debounce";

export default function SearchForm({
  setBooks,
  setInputIsClicked,
  inputIsClicked,
  setLoader,
}) {
  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length > 0) {
      setLoader(true);
      searchBooks(value)
        .then((res) => {
          if (res?.data?.totalCount > 0) {
            setBooks(res.data);
            setLoader(false);
          } else {
            setLoader(false);
            setBooks("Kitob topilmadi");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoader(false);
      setBooks();
    }
  };

  // eslint-disable-next-line
  const optimisedVersion = useCallback(debounce(handleChange), []);

  return (
    <div className="search-box-wrapper">
      <div className="search-wrapper-wide search">
        <input
          type="text"
          name="search"
          className="form-control"
          placeholder="Kitob qidirish..."
          onChange={optimisedVersion}
          required
        />
        <button
          className="btn search-button"
          onClick={() => setInputIsClicked(!inputIsClicked)}
        >
          <img src="/assets/images/shop-one/serach-icon.svg" alt="Search" />
        </button>
      </div>
    </div>
  );
}
