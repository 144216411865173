import React from "react";
import Logo from "../Logo";
export default function Footer() {
	return (
		<footer className="footer">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12 text-center">
							<Logo />
						</div>
						<div className="col-md-6 col-12 d-flex align-items-center telegram__channel">
							<img src="/assets/images/shop-one/telegramm.png" alt="telegram" />
							<a
								href="https://t.me/mehr_kutubxonasi"
								target="_blank"
								rel="noreferrer"
								className="ms-2"
								style={{
									fontWeight: 600,
									lineHeight: 1.1,
								}}
							>
								<b style={{ color: "black" }}>Biz telegramda</b> <br />
								@mehr_kutubxonasi
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-bootom">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="footer-bootom-content display-flex justify-content-between">
								<h5>Mehr kutubxonasi</h5>
								<ul className="social-media">
									{/* <li>
										<a href="https://t.me/mehr_kutubxonasi">
											<img
												src="/assets/images/shop-one/telegramm.png"
												alt="telegram"
											/>
											<span>@mehr_kutubxonasi</span>
										</a>
									</li> */}
									<li>
										<a href="tel:998909359034">
											<img
												src="/assets/images/shop-one/telephone.png"
												alt="phone"
											/>
											<span>(90) 935-90-34</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
