import React from "react";
import { Link } from "react-router-dom";

export default function Logo() {
	return (
		<Link to="/" className="logo">
			<img src="/assets/images/shop-one/svg-hor-logo-mehr.svg" alt="Logo" />
		</Link>
	);
}
