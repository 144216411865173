import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProduct } from "../../api/gatProduct";

export default function ProductPage(props) {
  const productId = props.match.params.id;
  const [product, setProduct] = useState();

  useEffect(() => {
    getProduct(productId).then((res) => {
      setProduct(res.data);
    });
  }, [productId]);

  const busyBooksCount = product?.stocks?.filter(
    (busy) => busy.busy === true
  ).length;
  const notBusyBooksCount = product?.stocks?.filter(
    (busy) => busy.busy === false
  ).length;

  return product ? (
    <div className="product-page">
      <div className="container">
        <div className="product-page-header">
          <Link to="/" className="btn back-button p-0">
            <img src="../../assets/images/arrow_back.svg" alt="back" />
          </Link>
          <h3>Kitob</h3>
        </div>
        <div className="row">
          <div className="col-md-5 col-12">
            <div className="product-media">
              <img
                src={
                  product.image
                    ? product.image
                    : `/assets/images/shop-one/no-img.png`
                }
                alt={product.name}
              />
            </div>
          </div>
          <div className="col-md-7 col-12">
            <div className="product-info">
              <div className="product-name">
                <h2>{product.name}</h2>
              </div>
              {product.author?.name ? (
                <div className="product-author">
                  <h3>Muallif:&nbsp;{product.author?.name}</h3>
                </div>
              ) : null}

              {product.description ? (
                <div className="about-product">
                  <h3>Kitob haqida: &nbsp;{product.description}</h3>
                </div>
              ) : null}
              <div className="product-count">
                <h4>
                  Umumiy kitoblar:&nbsp;
                  {product.stocks?.length}
                </h4>
                <h4>Band kitoblar:&nbsp;{busyBooksCount}</h4>
                <h4>
                  Bo'sh kitoblar:&nbsp;
                  {notBusyBooksCount}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
