import React from "react";
import { useState } from "react";
import ProductModal from "../../../../components/common/partials/modals/ProductModal";
import { NO_IMAGE } from "../../../../constants/constants";

export default function ProductCard({ product }) {
	const [modal, setModal] = useState(false);

	return (
		<>
			<div className="product-card" onClick={() => setModal(!modal)}>
				<div className="card-content">
					<div className="media product-header">
						<img src={product.image || NO_IMAGE} alt={product.name} />
					</div>
					<div className="product-body">
						<h3 className="product-title">{product.name}</h3>
						<h4 className="product-author">{product.author?.name}</h4>
					</div>
				</div>
				<div className="btns">
					{product.stocks.every((busy) => busy.busy === true) ? (
						<button className="btn state remember">BAND</button>
					) : (
						<button className="btn state add-busy">BO'SH</button>
					)}
				</div>
			</div>
			{modal ? (
				<ProductModal product={product} modal={modal} setModal={setModal} />
			) : null}
		</>
	);
}
