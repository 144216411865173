export function setHeader({
	title = "",
	meta_description = ""
}) {
	if (title) {
		document.head.getElementsByTagName("title")[0].innerHTML = title + " - Mehr kutubxonasi"
	}

}

export function setTitle(title) {
	document.head.getElementsByTagName("title")[0].innerHTML = title
}

export function getTitle() {
	return document.head.title
}