import React from "react";
import { setHeader } from "../../utils/head_tools";
import TelegramEmbed from "react-telegram-embed";

export default function Address() {
	setHeader({ title: "Manzil" });
	return (
		<div className="container my-3">
			<TelegramEmbed src="https://t.me/mehr_kutubxonasi/1559" />
		</div>
	);
}
