import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import Loader from "../components/features/loaders/Loader";
// import LoaderOverlay from "../components/features/loaders/LoaderOverlay";
import Layout from "../components/layout/Layout";
// import market from "../config/market";
// import Login from "./home/components/login/Login";
// import Profile from "./home/components/profile/Profile";
import Home from "./home";
import ProductPage from "./product";
import NotFound from "./pages";
import SearchPage from "./pages/SearchPage";
import GetBook from "./pages/GetBook";
import ToContribute from "./pages/ToContribute";
import Satistics from "./home/components/blocks/Satistics";
import Test from "./pages/Test";
import Contract from "./Contract";
import Address from "./pages/Address";
import Important from "./home/components/blocks/Important";
import RentExpiredPage from "./pages/RentExpiredPage";

export default function AppRoot() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route path="/login" component={Login}></Route> */}
        {/* <Route path="/profile" component={Profile}></Route> */}
        <Route path="/s/:phone" component={RentExpiredPage}></Route>
        <Route path="/product" component={ProductPage}></Route>
        <Route path="/search" component={SearchPage}></Route>
        <Route path="/shartnoma" component={Contract}></Route>
        <Route path="/hissa" component={ToContribute}></Route>
        <Route path="/zarur" component={Important}></Route>
        <Route path="/kitob-olish" component={GetBook}></Route>
        <Route path="/manzil" component={Address}></Route>
        <Redirect from="/hissa-qoshish" to="/hissa" />
        <Route path="/statistika" component={Satistics}></Route>
        <Route path="/test" component={Test}></Route>
        <Route component={NotFound}></Route>
      </Switch>
    </Layout>
  );
}
