import React, { useEffect, useState } from "react";
import { getStats } from "../../../../api/getStats";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

export default function Stats() {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    getStats().then((res) => {
      setStats(res?.data);
    });
  }, []);

  return (
    <div className="container stats">
      <div className="row my-5 text-center">
        <div className="col-12 mb-4">
          <h2>Kutubxona statistikasi</h2>
          <Link to="/statistika" className="view-all">
            <b>To’liq ko’rish</b>
          </Link>
        </div>
        <div className="col-6 col-lg-3">
          <div className="icon">
            <img
              src="/assets/images/shop-one/man-teacher.png"
              alt="Librarians"
            />
          </div>

          <h2>
            <CountUp end={stats.librarians_count} />
          </h2>
          <div className="h4">Kitobxonlar</div>
        </div>
        <div className="col-6 col-lg-3">
          <div className="icon">
            <img
              src="/assets/images/shop-one/open-book.png"
              alt="Reading-books"
            />
          </div>
          <h2>
            <CountUp end={stats.reading_books_count} />
          </h2>
          <div className="h4">O'qilayotgan kitoblar</div>
        </div>
        <div className="col-6 col-lg-3">
          <div className="icon">
            <img src="/assets/images/shop-one/check-mark.png" alt="Mark" />
          </div>
          <h2>
            <CountUp end={stats.rents_count} />
          </h2>
          <div className="h4">O'qilgan kitoblar</div>
        </div>
        <div className="col-6 col-lg-3">
          <div className="icon">
            <img src="/assets/images/shop-one/books.png" alt="Books" />
          </div>
          <h2>
            <CountUp end={stats.books_count} />
          </h2>
          <div className="h4">Barcha kitoblar</div>
        </div>
      </div>
    </div>
  );
}
