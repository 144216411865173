import React from "react";
import ProductCard from "../../../../views/home/components/product_card/ProductCard";
import Loader from "../../../features/loaders/Loader";

export default function SearchResult({ books, loader }) {
  return (
    <div className="search-result-wrapper">
      <div className="row">
        {loader ? (
          <Loader />
        ) : (
          <>
            {books.totalCount > 0 ? (
              books.items.map((book, i) => (
                <div className="col-md-3 col-12" key={i}>
                  <ProductCard product={book} />
                </div>
              ))
            ) : (
              <h3 className="text-center">Kitob topilmadi</h3>
            )}
          </>
        )}
      </div>
    </div>
  );
}
