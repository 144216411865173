export const URL_LIBRARY_API = "https://library.softly.uz/api";

export const URL_LIBRARY = "https://library.softly.uz";

export const userTypes = {
	SET_USER_DATA: "SET_USER_DATA",
	SET_USER_PHONE_NUMBER: "SET_USER_PHONE_NUMBER",
	REMOVE_USER: "REMOVE_USER",
};
export const SIGN_IN_ACTION = "SIGN_IN_ACTION";
export const CHECK_USER_PHONE = "CHECK_USER_PHONE";
export const CHECK_USER_PASSWORD = "CHECK_USER_PASSWORD";
export const SIGN_OUT_ACTION = "SIGN_OUT_ACTION";
export const NO_IMAGE = "https://telegra.ph/file/ee79afc5d58364c4ff64f.jpg";
