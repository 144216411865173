import React from "react";
import { setHeader } from "../../utils/head_tools";

export default function GetBook() {
	setHeader({ title: "Kitob olish tartibi" });
	return (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12 col-lg-8 col-md-8 fs-5">
					<div>
						<b>✳️ Китоб олмоқчи бўлсангиз</b>
					</div>
					<br />
					<div>
						<b>ИЛТИМОС ДИҚҚAТ БИЛAН ОХИРГAЧA ЎҚИНГ </b>
						<br />
						(керакли маълумотлар айтилади)
					</div>
					<br />
					<div>
						Aгар сиз биринчи марта китоб олмоқчи бўлсангиз рўйхатга олишимиз
						керак бўлади. Бунинг учун кутубхонамизга паспортингиз ва паспорт
						нусхасини олиб келишингиз керак бўлади. Паспорт нусхасини олиб
						қоламиз. Фақат биринчи марта. <br /> Кутубхонага боришдан аввал
						<a href="https://t.me/kutubxonachi_1" className="fs-5">
							@kutubxonachi_1
						</a>{" "}
						га қуйидаги маълумотларни ёзиб қолдиринг <br /> — исм, фамилия;{" "}
						<br /> — яшаш манзилингиз (туман, кўча, уй рақами); <br /> — телефон
						рақамингиз (телеграми бўлган рақамингиз ва алоқа учун рақамингиз,
						агар алоҳида бўлса); <br /> — олмоқчи бўлган китобингиз номи
					</div>
					<br />
					<div>
						<b>⚠️ Эслатма</b>
						<br /> — Китоблар рўйхатини, банд ёки банд эмаслигини ботимиздан
						билиб олишингиз мумкин{" "}
						<a href="https://t.me/mkitobot" className="fs-5">
							@mkitobot
						</a>{" "}
						👈 <br />— Янги (биринчи марта олаётган) китобхонларга фақат 1 та
						китоб берилади!
					</div>
					<br />
					<div>
						<b> ❗️Диққат</b> <br />
						Кутубхонадан китоб олган китобхон, кутубхона асосий каналидан{" "}
						<a href="https://t.me/mehr_kutubxonasi" className="fs-5">
							@mehr_kutubxonasi
						</a>{" "}
						ва боти{" "}
						<a href="https://t.me/mkitobot" className="fs-5">
							@mkitobot
						</a>{" "}
						чиқиб кетиши мумкин эмас. Муҳим ҳабарлар юбориб турилади
					</div>

					<br />
					<div>
						Aгар сиз излаган китоб бўлмаса ҳафа бўлишга хожат йўқ, бизга
						ёзсангиз, имкониятимиз бўлса кутубхонага сиз учун янгисини оламиз
					</div>
					<br />
					<div>
						Вилоятларда ҳам кутубхоналар бор. Вилоятдагилар{" "}
						<a href="https://t.me/ilmuchunehson" className="fs-5">
							@ilmuchunehson
						</a>{" "}
						👈 шу гурухга мурожаат қилишингиз мумкин
					</div>
					<br />

					<div>
						<b>✳️ Муҳим</b>
						<br />
						Кутубхонамиз Тошкент шаҳрида. Ҳамкорлик
						<a href="https://t.me/ibrohim_mirxoshimov" className="fs-5">
							@ibrohim_mirxoshimov{" "}
						</a>{" "}
						👈
						<br />
						<br />
						<b>✳️ Китоб олиш мутлақо бепул</b>
						<br />
						Китобларни ўқиб туришга берамиз. Aллоҳ розилиги учун. <br />
						<br />
					</div>
					<div>
						<b>🗺 Кутубхона манзили</b>
						<br />
						<b>Манзил:</b> Тошкент савдо маркази, Зиёнур китоблар дўкони, 4 -
						дўкон
						<b>
							<br />
							<a href="https://t.me/mehr_kutubxonasi/614" className="fs-5">
								📍Локация
							</a>
						</b>
						<br />
						<b>Мўлжал:</b> Чорсу, Кўкалдош, Китоблар дунёси рўпараси <br />
						<b>Иш вақти:</b> 10:00 дан 17:30 гача <br />
						<b>Дам олиш куни:</b> ЖУМА
					</div>
					<br />
					<b>Кутубхонадан фото лавхалар</b>
					<div className="library-images">
						<div>
							<img src="/assets/images/shop-one/library1.png" alt="" />
							<img src="/assets/images/shop-one/library2.png" alt="" />
						</div>
						<img src="/assets/images/shop-one/library3.jpg" alt="" />
					</div>
					<br />
					<div>
						<b>✳️ Яқинларингизни гуруҳимизга таклиф қилинг.</b> <br />
						Кутубхонамиз ҳақида ҳабар беринг. Савобли ишга сабабчи бўлиб
						қолсангиз ва сиз орқали бир китобни ўқисалар, иншаAллоҳ сизга ҳам
						савобидан бориб туради.
					</div>
					<br />
					<div>
						<b>
							✳️ Сиз ҳам кутубхонага эҳсон қилиб кимнингдир илм олишига сабабчи
							бўлишингиз мумкин
						</b>
						<br />
					</div>
					<div>
						<b>Карта: </b> <code>8600140233909770</code>
					</div>
					<div>(Мирхошимов И.)</div>
				</div>
			</div>
		</div>
	);
}
