import React from "react";
import market from "../../config/market";
import { setTitle } from "../../utils/head_tools";
import HomePageDefaul from "./home-one";

export default function HomePage() {
	setTitle("Mehr kutubxonasi - Bepul kutubxona");
	switch (market.config.home) {
		case 1:
			return <HomePageDefaul key="1" />;
		default:
			return <HomePageDefaul key="1" />;
	}
}
