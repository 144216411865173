import axios from "axios";
import { URL_LIBRARY_API } from "../constants/constants";

export const getBooks = (size, page, isBusy, query) => {
	return axios.get(
		`${URL_LIBRARY_API}/app/books?size=${size}&page=${page}&order=DESC${
			isBusy !== undefined ? `&busy=${isBusy}` : ``
		}${query ? `&q=${query}` : ``}`
	);
};
