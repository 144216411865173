import React from "react";
import CategoriesTab from "../../../components/common/menus/desktop/CategoriesTab";
import Stats from "../components/blocks/Stats";

export default function HomePageDefaul() {
  function toTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <div className="home-page main">
        <button className="btn scroll-to-top" onClick={toTop}>
          <img src="/assets/images/up-arrow.png" alt="top" />
        </button>
        <Stats />
        <div className="categories">
          <CategoriesTab />
        </div>
      </div>
    </>
  );
}
